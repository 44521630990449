import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import {format as formatDate, formatDistance, formatRelative, isDate} from 'date-fns';
import {cs, enUS as en} from "date-fns/locale"; // import all locales we need
const locales = {en, cs};

const moneyFormatters={
    cs: new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: 'CZK',
        maximumFractionDigits: 0
    }),
    en: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CZK',
        maximumFractionDigits: 0
    }),
};

i18n
    // detect user language
    .use(Backend)
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(intervalPlural)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: 'cs',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: (value, format, lng) => {
                lng = lng.substring(0,2);
                if (isDate(value)) {
                    const locale = locales[lng];
                    if (format === "short") {
                        return formatDate(value, "P", {locale});
                    }
                    if (format === "long") {
                        return formatDate(value, "PPPP", {locale});
                    }
                    if (format === "relative") {
                        return formatRelative(value, new Date(), {locale});
                    }
                    if (format === "ago") {
                        return formatDistance(value, new Date(), {locale, addSuffix: true});
                    }
                    return formatDate(value, format, {locale});
                }
                if(format === 'money'){
                    return moneyFormatters[lng].format(value);
                }
                if (value === null) {
                    //return '';
                    return '<null>';
                }
                if (value === undefined) {
                    //return '';
                    return '<undefined>';
                }
                return value;
            }
        }
    });

export default i18n;
